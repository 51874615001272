import { getCommonParamsItemLabel } from '@/utils/common'
import store from '@/store'

export const contentTableConfig = {
  propList: [
    {
      prop: 'name',
      label: 'model.name'
    },
    {
      prop: 'order',
      label: 'category.order'
    },
    {
      prop: 'brandType',
      label: 'terminal.brand',
      width: 150,
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'brand_type', {
          isNeedPreFix: false
        })
      }
    },
    {
      prop: 'appType',
      label: 'file.application-type',
      width: 150,
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'app_type')
      }
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'description',
      label: 'general.description',
      width: 180
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 180
    },
    {
      label: 'common.app-operation',
      width: 280,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
